import React from "react"
import classNames from 'classnames';
import rocketIcon from '../../images/icons/rocket2.svg'
import securityIcon from '../../images/icons/security2.svg'
import thumbIcon from '../../images/icons/thumb.svg'
import contentIcon from '../../images/icons/content2.svg'
import responsiveIcon from '../../images/icons/responsive.svg'
import seoIcon from '../../images/icons/seo.svg'
const AmazingFeatures = () => {
    return (
        <div className="section pt-16 pb-8 overflow-auto">
            <div className="container mx-auto p-4 h-full">
                {/*<h1 className="text-indigo-700 text-4xl uppercase tracking-wider text-center font-bold">Amazing Features</h1>
                <div className="title-line border-b mx-auto mt-6 border-indigo-700 w-48 h-2"></div>*/}


                <div className="features lg:flex  justify-between">
                    <div className="w-full flex flex-wrap shadow">
                        <h3 className="bg-blue-700 text-white w-full p-6 rounded text-xl leading-tight mb-8 font-hero text-center">
                            We specialize in <b className="">headless-decoupled</b>, <b>fast</b>, <b>static</b>, <b>mobile first</b>, <b>SEO friendly</b> websites.
                        </h3>
                        <div className="p-6 flex flex-wrap ">
                            <FeatureItem position="right" title="Speedy Fast" desc="Static sites loads much faster than the pages on a dynamic site. Fast websites are really important for a good user experience, and also for boosting your site in search engine rankings." icon={rocketIcon} />
                            <FeatureItem position="left" title="Responsive Design" desc="Make your site mobile-friendly, improve the way it looks on devices with both large and small screens, and increase the amount of time that visitors spend on your site." icon={responsiveIcon} />
                            <FeatureItem position="right" title="SEO-friendly" desc="Static websites get better Lighthouse scores and are more visible and easier to index by Google" icon={seoIcon} />
                            <FeatureItem position="left" title="Secure" desc="Static sites are more secure than dynamic sites. In most cases, they are much more secure." icon={securityIcon} />
                            <FeatureItem position="right" title="Futureproofing your website" desc="In future, it will be far easier to change the design of the site if needed" icon={thumbIcon} />
                            <FeatureItem position="left" title="Ease of editing content" desc="We provide an easy way for non-technical users to edit and publish content within a friendly user interface" icon={contentIcon} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

const FeatureItem = ({position, title, desc, icon}) => {
    const isRightPosition = position === 'right'
    const borderClass = classNames('w-full lg:w-1/2', {'lg:border-r' : isRightPosition})
    return (
        <div className={borderClass}>
            <div className={`flex items-start align-middle mb-16 ${isRightPosition ? 'lg:mr-8  lg:flex-row-reverse' : 'lg:ml-8' }`}>
                <div className="w-12 pt-1">
                    <img src={icon} className="block mx-auto w-full fill-current" alt=""/>
                </div>
                <div className={`w-10/12 ml-12  ${isRightPosition ? 'lg:ml-0 lg:mr-8' : 'lg:ml-8'}`}>
                    <h3 className={`text-xl font-semibold font-hero  ${isRightPosition ? 'lg:text-right' : 'text-left' }`}>{title}</h3>
                    <div className="text-justify">{desc}</div>
                </div>
            </div>
        </div>
    )
}

export default AmazingFeatures;
