import React from 'react';
import {StyledBgImgSection} from "../Header/styles";
import bgWave from "../../images/bg/bg-wave.svg";
import {graphql, Link, useStaticQuery} from "gatsby";
import Img from "gatsby-image"
const Hero = () => {
	const  data  = useStaticQuery(
		graphql`
	      query {
		    file(relativePath: { eq: "hero/main.png" }) {
		      ...fluidImage
		    }
		  }
	    `
	)
	return (
		<StyledBgImgSection className="background-img relative w-full block pt-24">
			<div className="container mx-auto flex flex-wrap px-4 xl:px-0 py-12 items-center justify-between">
				<div className="w-full md:w-6/12 text-white z-10">
					<h1 className="text-white text-3xl lg:text-5xl leading-tight mb-4 font-hero">Fast Static Sites Development Agency</h1>
					<p className="text-xl mb-10">
						We create reactive quick websites.
						Do not let your users wait for the site to load!

						A 1 second delay in page response can result in a 7% reduction in conversions.
					</p>
					<Link to="/contact" className="w-full block text-center sm:inline py-3 px-6 bg-orange-500 rounded">Contact with us</Link>
				</div>
				<div className="w-full md:w-5/12 z-10">
					<div>
						<Img fluid={data.file.childImageSharp.fluid} className="block max-w-full h-auto" alt="Fast Static Sites Development Agency" />
					</div>
				</div>
			</div>
			<div className="relative" style={{bottom:'-5px'}}>
				<img src={bgWave} alt="wave shape" className="w-full" />
			</div>
		</StyledBgImgSection>
	);
};

export default Hero;
