import React from 'react';
import gapychPortfolioImage from "../../images/portfolio/gapych2.png";

const FeaturedPortfolio = () => {
	return (
		<div className=" mx-auto">
			<div className="w-full lg:w-9/12 mx-auto text-center my-12">
				<h2 className="text-4xl leading-tight font-hero">Featured Static Site</h2>
			</div>
			<div className="flex flex-wrap">
				<div className="w-full lg:w-2/3">
					<img src={gapychPortfolioImage} className="max-h-screen"/>
				</div>
				<div className="w-full lg:w-1/3 flex">
					<div className="m-auto p-10 shadow">
						<div className="font-hero text-xl font-bold">Gapych.com</div>
						<p className="font-hero ">Tampa Real Estate Agent Personal Website </p>

						<div className="my-5">
							<div className="font-hero ">Stack:</div>
							<div className="flex">
								<img className="w-8 mr-5" src="https://images.prismic.io/jamchefs%2F2426e70b-66bd-4c7e-aea5-15e94901392f_prismic-logo-monogram.svg?auto=compress,format" alt="Featured project technologies - Prismic icon" />
								<img className="w-8 mr-5" src="https://images.prismic.io/jamchefs%2Fdbb0bee2-5afc-4151-9ff9-9530f1ace24a_netlify_logo.svg?auto=compress,format" alt="Featured project technologies - Netlify icon" />
								<img className="w-8 mr-5" src="https://images.prismic.io/jamchefs%2F98a5af15-9dab-4883-9b83-7ae329c6d826_i_mailchimp.svg?auto=compress,format" alt="Featured project technologies - Mailchimp icon" />
								<img className="w-8 mr-5" src="https://images.prismic.io/jamchefs%2Fd5b47aff-5432-4a62-825e-9790124d8184_gatsby_logo.svg?auto=compress,format" alt="Featured project technologies - GatsbyJs icon" />
							</div>
						</div>
						<a href="https://gapych.com" className="inline-block mt-2 rounded p-2 bg-blue-800 text-white">Open Website</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FeaturedPortfolio;
