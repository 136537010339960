import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero/Hero";
import OurFeatures from "../components/Sections/OurFeatures";
import OurServices from "../components/Sections/OurServices";
import FeaturedPortfolio from "../components/Sections/FeaturedPortfolio";
import Contact from "../components/Contact/Contact";

function IndexPage() {
	return (
		<Layout>
			<SEO
				title="Fast Optimized Static Sites Development"
				description="We create fast, optimized, responsive, mobile first, static websites using Gatsby / React. Web Development Agency Tampa Florida"
				keywords={['static websites', 'gatsby website', 'react site', 'quick site', 'fast site', 'optimized webpage', 'responsive website', 'mobile firts sites', 'create site in Tampa', 'order website']}
			/>
			<Hero/>
			<OurFeatures />
			<OurServices/>
			<FeaturedPortfolio/>
			<Contact />
		</Layout>
	);
}

export default IndexPage;
