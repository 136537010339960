import React from 'react';
import AmazingFeatures from "../AmazingFeatures/AmazingFeatures";

const OurFeatures = () => {
	return (
			<div className="container mx-auto mt-12 text-lg  ">
				<h2 className="text-4xl leading-tight mb-8 font-hero text-center">What are we doing differently?</h2>


				<div className="p-2 text-center">
					<h2 className="text-2xl font-hero">Headless Architectures Are the Future</h2>
					<p>Monolithic architecture has served us well. Headless microservices are increasingly being adopted by larger, enterprise companies with more complexity in their product chains and marketing content delivery needs.</p>
				</div>

				<AmazingFeatures />


				{/*<p className="font-bold">Wait, let me explain it to you!</p>
					<div className="shadow rounded p-4">
						<p><b>Monolithic architecture</b> has served us well. Now the monolithic architecture of web applications is an outdated method of creating web sites. </p>
						<div className="flex">
							<div className="w-1/2">
								<div>Not reusable</div>
							</div>
						</div>
					</div>
					<div className="flex mt-4">
						<div className="w-1/2 mr-3 shadow rounded">
							<div className="p-2 bg-red-400 text-center rounded-t">Monolithic architecture</div>
							<div className="p-2">
								<p>Monolithic architecture has served us well.</p>
								<p>Most existing websites are built on a monolithic architecture. And for many years since the first sites began to appear, it was a good structure.</p>

								<p>However, when mobile Internet usage began to exceed desktop capabilities around 2014, the all-in-one element of the monolithic architecture became a hindrance rather than an enabler. Businesses now needed to serve consumers on different devices and channels.</p>
								<p>Now the monolithic architecture of web applications is an outdated method of creating web sites. </p>
								<p>The cons of monolithic architecture:</p>
								<ul className="list-inside list-disc text-">
									<li>Monolithic applications has a barrier to adopting new technologies.</li>
									<li><b>Large</b> - application is too large and complex to fully understand and made changes fast and correctly.</li>
									<li><b>Slow</b> - the size of the application can slow down the start-up time.</li>
									<li><b>Complexity</b> - Limitation in size</li>
									<li><b>Reliability</b> - Bug in any module can bring down the whole website</li>
								</ul>
							</div>
						</div>
						<div className="w-1/2 ml-3 shadow">
							<div>Monolithic architecture</div>
							<p>Monolithic architecture has served us well.</p>
							<p>Most existing websites are built on a monolithic architecture. And for many years since the first sites began to appear, it was a good structure.</p>

							<p>However, when mobile Internet usage began to exceed desktop capabilities around 2014, the all-in-one element of the monolithic architecture became a hindrance rather than an enabler. Businesses now needed to serve consumers on different devices and channels.</p>
							<p>Now the monolithic architecture of web applications is an outdated method of creating web sites. The cons of monolithic architecture:</p>
							<ul>
								<li>Monolithic applications has a barrier to adopting new technologies.</li>
								<li>Application is too large and complex to fully understand and made changes fast and correctly.</li>
								<li>The size of the application can slow down the start-up time.</li>
								<li>Has a limitation in size and complexity.</li>
								<li>Another problem with monolithic applications is reliability. Bug in any module (e.g. memory leak) can potentially bring down the entire process. </li>
							</ul>
						</div>
					</div>*/}


			</div>
	);
};

export default OurFeatures;
